<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Users</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" class="ml-2 primary" squared @click="addUser">Grant User Access</b-button>
            </b-col>
          </b-row>
          <b-row>
           
            <b-col cols="4">
              <label>Search</label>
              <b-form-input v-model="searchTerm" @keyup="search"></b-form-input>
            </b-col>
          </b-row>             
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">
              <b-table striped hover
                       sort-icon-left
                       :items="tableData.dataSource"
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openUser"
                       :per-page="tableData.resultsPerPage"
                       id="SearchUser"
                       :current-page="tableData.currentPage">

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>


                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openUser(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="SearchUser"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>

import {mapMutations} from "vuex";
import api from "../../../api";

export default {
  name: "SearchUser",
  data: () => ({
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },

        {
          label: 'Document Types',
          key: 'docTypes',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
   
    dataSet: [],
    searchTerm: "",
    
  }),
  created() { 
    this.setBreadcrumb([
      {
        text: 'Users'
      },
    ]);

   const s = (accessList) => {

     api.DocumentGetUsers((users)=>{
       var dataSource = []
       this.dataSet = users;
       for (const user of users) {
         var entry={...user}
         entry["docTypes"]=accessList[user.id].join(', ')
         dataSource.push(entry)
       }
       this.tableData.dataSource = {...dataSource};
       this.dataSet=dataSource
     }, e);

       this.tableData.isLoading = false;
   };

   const e = (msg) => {
     console.log(msg);
   };

    api.DocumentGetUserAccess(s, e);
  },

  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
   

    search() {
      this.tableData.dataSource = this.dataSet.filter((x) =>
      x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) 
      || x.surname.toLowerCase().includes(this.searchTerm.toLowerCase()) 
      || x.email.toLowerCase().includes(this.searchTerm.toLowerCase())
      );

    },

   
    openUser(user) {
      this.$store.commit('setSelectedUser', user)
      localStorage.setItem("view_user_email", user.email);
      this.$router.push({path: '/admin/ManageUser/ViewUser'})
    },
    
    addUser() {
      this.$router.push({path: '/admin/ManageUser/CreateUser'})
    }
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>
<style scoped>

</style>